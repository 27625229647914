.tableBox td {
    border: 1px solid;
}
.datetime-picker {
    border-radius: 8px;
    border: 1px solid #ced4da;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    transition: border-color 0.3s, box-shadow 0.3s;
  }
  
.flightDateLabel {
    min-width: 202px;
}

@media ((min-width: 800px) and (max-width:1350px)) {
    .smartCalenderContainerCss {
        max-width: 100% !important;
        flex-basis: auto !important;
    }
}

.invalidInput {
    border: 2px solid red; /* Add a red border for invalid input */
    background-color: #ffeeee; /* Add a light red background color */
    color: red; /* Change the text color to red */
}

.FieldcontainerCss fieldset.MuiFormControl-root{
    min-width: 320px;
    max-width: auto;
}
.textInputResize{
    font-size: 16px;
    height: auto !important;
}
.isDisable {
    pointer-events: none;
    opacity: 0.5;
}

.flyerCardtextField .MuiInputBase-root {
    height: 42px !important;
}

.flyerCardtextField .MuiInputLabel-formControl {
    top: -7px !important;
}

.flyerCardtextField .MuiInputLabel-shrink {
    top: 0px !important;
}

.flyingCardMulti {
    height: 42px !important;
}

.textFieldMarginBFlyerCard {
    margin-bottom: 4px !important;
}

.multiFlyerCardContainer {
    position: relative;
}

.flyingCardMulti .rw-multiselect-tag {
    margin-left: calc(105px * 0.115 - 1px) !important;
    margin-top: calc(70px * 0.115 - 1px) !important;
}

.multiLabel {
    position: absolute;
    top: -6px;
    left: 13px;
    background-color: #fff;
    z-index: 2;
    font-size: 0.8em;
    padding: 0px 5px 0px 5px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.1876em;
    color: rgba(0, 0, 0, 0.57) !important;
    cursor: text;
    letter-spacing: 0.00938em;
}

.fareDisplayCabin .MuiFormControlLabel-labelPlacementBottom {
    margin-left: 16px;
    flex-direction: row !important;
}

/* cabin class selection filter */

.alignFormControl {
    margin: 0px auto !important;
}

.flexChild {
    flex-direction: row-reverse;
}

.preSelectCont {
    margin-left: 16px !important;
}

.labelContainer {
    justify-content: space-between !important;
    padding-left: 13px !important;
}

.showCabinCss {
    padding-left: 80px !important;
}

.rightCheckBoxAlignmentCss {
    justify-content: center !important;
    margin-right: 0px !important;
}

.colors .MuiInputBase-input {
    width: 40px !important;
}

.colors .MuiInput-underline:before {
    border-bottom: none !important
}

.colors .MuiInput-underline:after {
    border-bottom: none !important
}

.filterOrderContainer .MuiFormControlLabel-label{
    width: 250px;
}
.paymentDataSettingCard{
    width: auto !important;
    overflow-x: auto !important;
}

.MuiListItemIcon-root{
    min-width: 40px !important;
}

.cabinClasss>span {
    display: flex;
    gap: 2px;
    flex-direction: column;
    width: 110px;
}